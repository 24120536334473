import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import React, { Suspense, lazy, useEffect } from 'react'
import { telemetryService, trackError } from './utils/metrics/appInsights/AppInsights'

import AuthenticatedRoute from './auth/AuthenticatedRoute'
import CreateOrderConfirmationPage from './pages/order/CreateOrderConfirmationPage'
import CreateOrderPage from './pages/order/CreateOrderPage'
import Error from './pages/Error'
import Forbidden from './pages/Forbidden'
import HomePage from './pages/HomePage'
import Login from './pages/Login'
import NavigationBar from './components/layout/NavigationBar'
import NotFound from './pages/NotFound'
import OrderDeliveryConfirmationPage from './pages/order/OrderDeliveryConfirmationPage'
import OrdersPage from './pages/order/OrdersPage'
import PageLoading from './components/loading/PageLoading'
import ReportExpired from './pages/ReportExpired'
import Role from './types/enums/Role'
import SegmentWrapper from './utils/metrics/segmentWindow'
import Success from './pages/Success'
import { ThemeProvider } from '@mui/material/styles'
import instrumentationKey from './utils/metrics/appInsights/appInsightsKeyMapping'
import routes from './routes'
import theme from './assets/theme'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

const BuyerList = lazy(() => import('./pages/existing-customers/BuyerList'))
const BuyerPage = lazy(() => import('./pages/existing-customers/BuyerPage'))
const CreateOrdersPage = lazy(() => import('./pages/order/CreateOrdersPage'))
const CreateOrdersConfirmationPage = lazy(() => import('./pages/order/CreateOrdersConfirmationPage'))
const CustomerOnboardingPage = lazy(() => import('./pages/customer/CustomerOnboardingPage'))
const CustomerOnboardingResultsPage = lazy(() => import('./pages/customer/CustomerOnboardingResultsPage'))
const CustomerOnboardingErrorPage = lazy(() => import('./pages/customer/CustomerOnboardingErrorPage'))
const BulkCustomersOnboardingPage = lazy(() => import('./pages/bulk-customer/BulkCustomersOnboardingPage'))
const CustomerPaymentsPage = lazy(() => import('./pages/customerPayments/CustomerPaymentsPage'))
const CustomerPaymentDetails = lazy(() => import('./pages/customerPayments/CustomerPaymentDetails'))
const ImpersonateMerchantPage = lazy(() => import('./pages/impersonate/SelectImpersonatedMerchantPage'))

const queryClient = new QueryClient()

function App() {
  useEffect(() => {
    telemetryService.initialize(instrumentationKey(window.location.href))
    SegmentWrapper.page('MarketplacePortal')
  }, [])

  useEffect(() => {
    window.addEventListener('error', trackError)
    return () => {
      window.removeEventListener('error', trackError)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <NavigationBar>
            <Routes>
              <Route path={routes.forbidden} element={<Forbidden />} />
              <Route path={routes.success} element={<Success />} />
              <Route path={routes.login} element={<Login />} />
              <Route path={routes.error} element={<Error />} />
              <Route element={<AuthenticatedRoute Component={<HomePage />} />} path={routes.root} />
              <Route element={<AuthenticatedRoute Component={<OrdersPage />} />} path={routes.orders} />
              <Route
                element={<AuthenticatedRoute Component={<OrderDeliveryConfirmationPage />} />}
                path={`${routes.orderDeliveryConfirmation}`}
              />

              <Route
                element={<AuthenticatedRoute Component={<CreateOrderPage />} allowedRoles={[Role.Seller]} />}
                path={routes.createOrder}
              />
              <Route
                element={
                  <AuthenticatedRoute Component={<CreateOrderConfirmationPage />} allowedRoles={[Role.Seller]} />
                }
                path={routes.createOrderConfirmation}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    allowedRoles={[Role.KriyaUser]}
                    missingRoleRedirect={routes.root}
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <ImpersonateMerchantPage />
                      </Suspense>
                    }
                  />
                }
                path={routes.impersonate}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <BuyerList />
                      </Suspense>
                    }
                  />
                }
                path={routes.buyers}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <BuyerPage />
                      </Suspense>
                    }
                  />
                }
                path={routes.buyer}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <CreateOrdersPage />
                      </Suspense>
                    }
                    allowedRoles={[Role.Seller]}
                  />
                }
                path={routes.createOrders}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <CreateOrdersConfirmationPage />
                      </Suspense>
                    }
                    allowedRoles={[Role.Seller]}
                  />
                }
                path={routes.createOrdersConfirmation}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <BulkCustomersOnboardingPage />
                      </Suspense>
                    }
                    allowedRoles={[Role.Merchant, Role.Seller]}
                  />
                }
                path={routes.bulkCustomersOnboarding}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <CustomerOnboardingPage />
                      </Suspense>
                    }
                    allowedRoles={[Role.Merchant, Role.Seller]}
                  />
                }
                path={routes.customerOnboarding}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <CustomerOnboardingResultsPage />
                      </Suspense>
                    }
                    allowedRoles={[Role.Merchant, Role.Seller]}
                  />
                }
                path={routes.customerOnboardingResults}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <CustomerOnboardingErrorPage />
                      </Suspense>
                    }
                    allowedRoles={[Role.Merchant, Role.Seller]}
                  />
                }
                path={routes.customerOnboardingError}
              />
              <Route
                element={
                  <AuthenticatedRoute
                    Component={
                      <Suspense fallback={<PageLoading />}>
                        <CustomerPaymentsPage />
                      </Suspense>
                    }
                    allowedRoles={[Role.Merchant, Role.Seller]}
                  />
                }
                path={routes.payments}
              />
              <Route
                element={<AuthenticatedRoute Component={<CustomerPaymentDetails />} />}
                path={`${routes.payment}`}
              />
              <Route element={<AuthenticatedRoute Component={<ReportExpired />} />} path={routes.reportExpired} />
              <Route path={routes.notFound} element={<NotFound />} />
              <Route path="*" element={<Navigate to={routes.notFound} replace />} />
            </Routes>
          </NavigationBar>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const reactPlugin = telemetryService && telemetryService.reactPlugin

export default withAITracking(reactPlugin, App)
