import React from 'react'
import { BuyerAutocompleteDetails } from '../../../types/buyerAutocompleteDetails'
import { isRiskInReview, isRiskOnHold, isRiskRejected, isRiskSubmitted } from '../../../utils/riskDecisionUtils'
import RejectedBox from './RejectedBox'
import InReviewBox from './InReviewBox'
import BuyerInsufficientFundsBox from './BuyerInsufficientFundsBox'
import ValidBox from './ValidBox'
import {
  hasKycCheckResults,
  isKycCheckFailed,
  isKycCheckInProgress,
  isKycCheckNotStarted
} from '../../../utils/kycCheckUtils'
import KycCheckInProgressBox from './KycCheckInProgressBox'
import KycCheckNotStartedBox from './KycCheckNotStartedBox'

type BuyerInformationBoxProps = {
  buyerCompany: BuyerAutocompleteDetails | null
}

const BuyerInformationBox = ({ buyerCompany }: BuyerInformationBoxProps) => {
  if (
    isRiskRejected(buyerCompany?.riskDecision) ||
    (buyerCompany?.isKycCheckRequired && isKycCheckFailed(buyerCompany?.buyerKycStatus))
  ) {
    return <RejectedBox />
  }

  if (
    isRiskInReview(buyerCompany?.riskDecision) ||
    isRiskSubmitted(buyerCompany?.riskDecision) ||
    isRiskOnHold(buyerCompany?.riskDecision)
  ) {
    return <InReviewBox />
  }

  // Risk Approved cases
  if (buyerCompany?.isKycCheckRequired && isKycCheckNotStarted(buyerCompany?.buyerKycStatus)) {
    return <KycCheckNotStartedBox companyId={buyerCompany.companyId!} />
  }

  if (buyerCompany?.isKycCheckRequired && isKycCheckInProgress(buyerCompany?.buyerKycStatus)) {
    return <KycCheckInProgressBox />
  }

  if (buyerCompany?.availableSpendingLimit === 0) {
    return (
      <BuyerInsufficientFundsBox
        availableSpendingLimit={buyerCompany?.availableSpendingLimit}
        totalSpendingLimit={buyerCompany?.totalSpendingLimit}
        currency={buyerCompany?.currencyCode}
      />
    )
  }

  if (buyerCompany?.isKycCheckRequired && !hasKycCheckResults(buyerCompany?.buyerKycStatus)) {
    return <KycCheckNotStartedBox companyId={buyerCompany.companyId!} />
  }

  return (
    <ValidBox
      availableSpendingLimit={buyerCompany?.availableSpendingLimit}
      totalSpendingLimit={buyerCompany?.totalSpendingLimit}
      currency={buyerCompany?.currencyCode}
    />
  )
}

export default BuyerInformationBox
