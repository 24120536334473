import { BuyerAutocompleteDetails } from '../types/buyerAutocompleteDetails'
import { isRiskApproved } from './riskDecisionUtils'
import { isKycCheckPassed } from './kycCheckUtils'

export const isApprovedCompany = (value: BuyerAutocompleteDetails | null) => {
  const hasLimit = (value?.availableSpendingLimit || 0) > 0
  let isApproved = isRiskApproved(value?.riskDecision)
  if (value?.isKycCheckRequired) {
    isApproved = isApproved && isKycCheckPassed(value?.buyerKycStatus)
  }
  return isApproved && hasLimit
}

export const isOrderingProcessForCompanyCanBeContinued = (value: BuyerAutocompleteDetails | null) => {
  return value == null || (value != null && isApprovedCompany(value))
}
