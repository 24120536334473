import React, { useEffect, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import { BuyerAutocompleteDetails } from '../../types/buyerAutocompleteDetails'
import { CreateOrderRequest } from '../../types/CreateOrderRequest'
import CustomerContactDetails from './CustomerContactDetails'
import ErrorText from '../../components/input/FormErrors'
import OrderDetails from './OrderDetails'
import PaymentDetails from './PaymentDetails'
import { PaymentMethodsResponse } from '../../types/PaymentMethodsResponse'
import Stack from '@mui/material/Stack'
import StyledButtonAlone from '../../components/StyledButtonAlone'
import Typography from '@mui/material/Typography'
import content from '../content'
import { defaultCurrencyCode } from '../../consts'
import { fetchPaymentMethods } from '../../custom-hooks/useFetchPaymentMethods'
import { hasDifferentOrderCurrency } from '../../utils/multiCurrency'
import routes from '../../routes'
import theme from '../../assets/theme'
import { useCreateOrder } from '../../custom-hooks/useCreateOrder'
import { useExchangeRatesInfo } from '../../custom-hooks/useFetchExchangeRatesQuery'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { isApprovedCompany } from '../../utils/buyerAutocompleteDetailsUtils'
import { isRiskApproved, isRiskRejected } from '../../utils/riskDecisionUtils'
import { useFetchMerchantSpendingLimit } from '../../custom-hooks/useFetchMerchantSpendingLimit'

const pageContent = content.pages.createOrder

export enum FormState {
  OrderDetails,
  PaymentMethods
}

const CreateOrderPage = () => {
  const [componentKey, setComponentKey] = useState(1)
  const [buyerCompany, setBuyerCompany] = useState<BuyerAutocompleteDetails | null>(null)
  const navigation = useNavigate()
  const [formState, setFormState] = useState<FormState>(FormState.OrderDetails)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsResponse[]>([])
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [exchangeRate, setExchangeRate] = useState<number>(0)
  const [gbpExchangeRate, setGbpExchangeRate] = useState<number>(1)
  const { data, status } = useExchangeRatesInfo()
  const { data: merchantSpendingLimit } = useFetchMerchantSpendingLimit()
  const [orderCurrency, setOrderCurrency] = useState<string>(defaultCurrencyCode)
  const navigate = useNavigate()

  let continueProps: {
    onClick?: () => void
    text?: string
    isLoadingContent?: string
    isLoading?: boolean
  } = {}

  const defaultFormValues = {
    currencyCode: defaultCurrencyCode,
    contactDetails: {
      phoneNumber: ''
    }
  }
  const form = useForm<CreateOrderRequest>({
    mode: 'onChange',
    defaultValues: defaultFormValues,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  })
  form.register('companyId', {
    required: true,
    validate: {
      isValidCompany: () => buyerCompany?.riskDecision === 'Approved'
    }
  })
  const {
    formState: { isValid },
    trigger,
    reset
  } = form

  form.watch(async () => await form.trigger())

  // this is tmp dirty fix - this page incorrectly uses react-form-hook
  // and due to this validation works incorrectly
  const isFormValid = useMemo(() => {
    if (!buyerCompany) return false
    if (!isApprovedCompany(buyerCompany)) return false

    return isValid
  }, [buyerCompany, formState, isValid])

  const createOrderMutation = useCreateOrder({
    onError: (error) => {
      if (error && error.errors && error.errors.length > 0)
        setErrorMessage(error.errors[error.errors.length - 1].message)
    }
  })

  const onOrderDetailsCompleted = () => {
    setPaymentMethodsLoading(true)
    fetchPaymentMethods(form.getValues('amount'), form.getValues('companyId'))
      .then((data) => {
        setPaymentMethods(data)
        setFormState(FormState.PaymentMethods)
      })
      .catch(() => {
        setFormState(FormState.OrderDetails)
        setPaymentMethods([])
      })
      .finally(() => setPaymentMethodsLoading(false))
  }

  const onCreateOrder = () => {
    createOrderMutation.mutate(form.getValues(), {
      onSuccess: (data) => {
        navigation(routes.createOrderConfirmation, {
          state: data.data
        })
      }
    })
  }

  const onEdit = () => {
    setFormState(FormState.OrderDetails)
    setPaymentMethods([])
  }

  useEffect(() => {
    trigger().catch((r) => console.warn(r))
  }, [trigger, form])

  switch (formState) {
    case FormState.OrderDetails:
      continueProps = {
        isLoadingContent: pageContent.actions.processingPaymentMethods,
        onClick: onOrderDetailsCompleted,
        text: pageContent.actions.continue,
        isLoading: paymentMethodsLoading
      }
      break
    case FormState.PaymentMethods:
      continueProps = {
        onClick: onCreateOrder,
        text: pageContent.actions.submit,
        isLoading: paymentMethodsLoading
      }
      break
  }

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (data === null || data === undefined || status !== 'success') {
      navigate('/error')
      return
    }

    const selectedCurrency = event.target.value.toLowerCase()
    const buyerCurrencyCode = buyerCompany?.currencyCode?.toLowerCase() ?? defaultCurrencyCode
    const buffer = 1.1 //Adding a buffer of 10% to exchange rate

    if (hasDifferentOrderCurrency(selectedCurrency, buyerCurrencyCode)) {
      setExchangeRate(data[selectedCurrency][buyerCurrencyCode] * buffer)
    }

    if (hasDifferentOrderCurrency(selectedCurrency, defaultCurrencyCode)) {
      setGbpExchangeRate(data[selectedCurrency][defaultCurrencyCode.toLowerCase()] * buffer)
    }

    setOrderCurrency(selectedCurrency)
  }

  const isCompanyEligible =
    buyerCompany == null ||
    (isRiskApproved(buyerCompany.riskDecision) && (buyerCompany.availableSpendingLimit || 0) > 0)

  return (
    <div key={componentKey}>
      <Typography variant={'h1'} sx={{ marginBottom: theme.spacing(16) }}>
        {pageContent.title}
      </Typography>
      <Typography variant={'body1'} sx={{ marginBottom: theme.spacing(24) }}>
        {pageContent.heading}
      </Typography>

      <OrderDetails
        form={form}
        formState={formState}
        onEdit={onEdit}
        buyerCompany={buyerCompany}
        setBuyerCompany={setBuyerCompany}
        onCurrencyChange={handleCurrencyChange}
        exchangeRate={exchangeRate}
        gbpExchangeRate={gbpExchangeRate}
        isCompanyEligible={isCompanyEligible}
        merchantSpendingLimit={merchantSpendingLimit}
      />

      {isCompanyEligible && <CustomerContactDetails form={form} />}

      {formState === FormState.PaymentMethods && (
        <PaymentDetails form={form} paymentMethods={paymentMethods} exchangeRate={exchangeRate} />
      )}

      {errorMessage && <ErrorText id={'order-create-error-message'}>{errorMessage}</ErrorText>}

      <Stack direction="row" spacing={theme.spacing(6)} sx={{ marginTop: theme.spacing(20) }}>
        {isCompanyEligible && (
          <>
            <StyledButtonAlone id={'continue-order-button'} disabled={!isFormValid} {...continueProps}>
              {continueProps.text}
            </StyledButtonAlone>
            <Button variant="text" onClick={() => navigation(routes.root)}>
              {pageContent.actions.cancel}
            </Button>
          </>
        )}
        {!isCompanyEligible && (
          <Button
            id="start-over-button"
            variant="contained"
            onClick={() => {
              setBuyerCompany(null)
              reset()
              setTimeout(() => {
                trigger().catch((r) => console.warn(r))
                setComponentKey(Math.random())
              }, 10)
            }}
          >
            {isRiskRejected(buyerCompany?.riskDecision)
              ? pageContent.actions.startAgain
              : pageContent.actions.anotherCustomer}
          </Button>
        )}
      </Stack>
    </div>
  )
}

export default CreateOrderPage
