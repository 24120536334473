import routes, { externalRoutes } from '../routes'

import Role from '../types/enums/Role'
import { buyerListRiskDecisions } from '../types/buyerRiskDecisions'
import { directorVerificationStatus } from '../types/directorVerificationStatus'

const content = {
  navBar: {
    viewExistingCustomers: 'View existing customers',
    requestSpendingLimit: 'Conduct spending limit analysis',
    logo: {
      alt: 'KriyaLogo',
      url: {
        loggedInUrl: routes.root,
        loggedOutUrl: externalRoutes.officialWebsite
      }
    },
    navBarItems: [
      {
        title: 'Orders',
        children: [
          {
            title: 'Create an order',
            url: routes.createOrder,
            requiredRole: Role.Seller
          },
          {
            title: 'Create orders in bulk',
            url: routes.createOrders,
            requiredRole: Role.Seller
          },
          {
            title: 'View orders',
            url: routes.orders
          }
        ]
      },
      {
        title: 'Customers',
        children: [
          {
            title: 'Onboard a new customer',
            url: routes.customerOnboarding
          },
          {
            title: 'Bulk onboard new customers',
            url: routes.bulkCustomersOnboarding
          },
          {
            title: 'View existing customers',
            url: routes.buyers
          }
        ]
      },
      {
        title: 'Payments',
        children: [
          {
            title: 'View payments',
            url: routes.payments
          }
        ]
      }
    ],
    impersonation: {
      title: 'You’re impersonating ',
      switchAction: 'SWITCH'
    }
  },
  pages: {
    loading: {
      title: 'Getting everything ready...'
    },
    impersonate: {
      title: 'Select Merchant',
      submitButton: {
        title: 'Start'
      }
    },
    login: {
      title: 'Log in',
      description:
        "Please provide the email address you used when registering with our team. We'll send you a link that will allow you to easily log in.",
      submitButton: {
        title: 'Submit',
        loadingTitle: 'Submit...'
      }
    },
    forbidden: {
      title: 'Oops...this link is expired',
      description:
        "Please provide the email address you used when registering with our team. We'll send you a link that will allow you to easily log in.",
      submitButton: {
        title: 'Submit'
      }
    },
    reportExpired: {
      title: 'Oops...this report link is expired',
      description:
        'This link is no longer available to download the report. But don’t worry! You can still access all data and request reports through Kriya Merchant Portal. ',
      buyers: {
        description:
          'This link is no longer available to download the report. But don’t worry! You can still access the spending limit analysis results through Kriya Merchant Portal. '
      },
      orders: {
        description:
          'This link is no longer available to download the report. But don’t worry! You can still view all the orders and request reports through Kriya Merchant Portal. '
      },
      payments: {
        description:
          'This link is no longer available to download the report. But don’t worry! You can still view all the payments and request reports through Kriya Merchant Portal. '
      },
      backButton: {
        title: 'Go to Kriya Merchants Portal'
      }
    },
    notFound: {
      title: "Sorry we can't find this page"
    },
    error: {
      title: "We're sorry...",
      description:
        "Unfortunately, we can't support you with Marketplace Portal now. Please try again later. If you need urgent support, please contact your account manager.",
      backButton: {
        title: 'Back'
      }
    },
    success: {
      title: 'Thank you',
      description:
        "If your email is registered in our system, you will soon receive a link to log in. Should you encounter any further issues with logging in, please don't hesitate to reach out to your account manager."
    },
    buyerList: {
      title: 'Existing Customers',
      description:
        'This page shows 50 results only, listed in the alphabetical order of the company names. Use search and filter \n' +
        'to refine the display.',
      filters: {
        search: {
          label: 'Search',
          placeholder: 'Company name, registration number'
        },
        riskDecision: {
          label: 'Risk decisions',
          statuses: buyerListRiskDecisions
        },
        directorVerification: {
          label: 'Director ID verification',
          statuses: directorVerificationStatus,
          allSelected: 'All'
        }
      },
      kycCheckStatusDescriptions: {
        NotAvailable: 'N/A',
        PendingRiskDecision: 'Pending risk decision'
      },
      totalSpendingLimitDescriptions: {
        Ineligible: 'Ineligible',
        NotAssigned: 'Not assigned'
      },
      availableSpendingDescriptions: {
        Ineligible: 'Ineligible'
      }
    },
    orderList: {
      filters: {
        search: {
          label: 'Search',
          placeholder: 'Invoice number, company name and national ID'
        },
        orderStatus: {
          label: 'Order status'
        },
        paymentMethods: {
          label: 'Payment methods'
        },
        dateFrom: {
          label: 'Submitted date (from)'
        },
        dateTo: {
          label: 'Submitted date (to)'
        }
      },
      export: {
        button: 'Export',
        dialog: {
          heading: `We'll email you the report`,
          body: `Thank you for requesting the report. We're exporting the data now and it will be sent to your inbox.`,
          action: 'OK, I understand'
        }
      }
    },
    homePage: {
      faq: {
        title: 'FAQs',
        items: [
          {
            title: 'What is an order?',
            description:
              'An order is a request for an advance on a single invoice. The net amount advanced will depend on your advance rate and fees.'
          },
          {
            title: 'How many invoices can I upload for each order?',
            description:
              'Currently Kriya supports uploading 1 invoice at a time but in the future we will support bulk upload of invoices.'
          },
          {
            title: 'What are spending limits?',
            description:
              'Each of your customers are assigned a spending limit, against which you can request an advance on one of their submitted invoices. The limit of a customer is based on a careful analysis of your customer’s finances.'
          },
          {
            title: 'How can I ask for an increase on a customer’s spending limits?',
            description: 'Please contact your Kriya account manager who will walk you through the process.'
          },
          {
            title: 'What fees are charged per order?',
            description:
              'For every order advanced, Kriya charges a one-off fee as a percentage of the total order value, which is based on the payment method. Kriya reserves the right to charge late fees to your customer.'
          },
          {
            title: 'Will my customers be contacted?',
            description:
              'Kriya may contact your customers to confirm delivery of goods or services by email. If your customers are overdue for an invoice payment, they will be sent reminders by email until it passes through to the collections process.'
          },
          {
            title: 'What does each order status mean?',
            description:
              'Submitted = your order has been successfully created and the invoice is being verified,\n' +
              'ReadyToAdvance = your invoice has been verified and your customer is being financially verified,\n' +
              'Advanced = your order has been advanced,\n' +
              'Closed = your order has been fully repaid,\n' +
              'Overdue = your customer has not paid back for the order'
          },
          {
            title: 'Contact us',
            description: 'Please contact your Kriya account manager for any further questions.'
          }
        ]
      },
      actions: {
        title: 'What do you want to do today?',
        buttons: [
          {
            title: 'Create an order',
            description: 'Get advance funding for your invoice or order',
            route: routes.createOrder,
            requiredRole: Role.Seller
          },
          {
            title: 'Create orders in bulk',
            description: 'Create up to 50 orders for the same customer at once',
            route: routes.createOrders,
            requiredRole: Role.Seller
          },
          {
            title: 'View orders',
            description: 'Check order and customer payment statuses',
            route: routes.orders
          },
          {
            title: 'Onboard a new customer',
            description: 'Do this so you can start to get funding for new customers',
            route: routes.customerOnboarding
          },
          {
            title: 'Bulk onboard new customers',
            description: 'Add new customers at once',
            route: routes.bulkCustomersOnboarding
          },
          {
            title: 'View existing customers',
            description: 'Check the risk decision results and the limits of your customers',
            route: routes.buyers
          },
          {
            title: 'View customer payments',
            description: `Check your customers' payment reconciliation status`,
            route: routes.payments
          }
        ]
      },
      merchantSpendingLimit: {
        availableLimit: 'Available Limit',
        assignedLimit: 'Assigned Limit',
        usedLimit: 'Used Limit'
      }
    },
    orderDeliveryConfirmation: {
      title: 'Delivery confirmation',
      titleTooltip:
        'It is a method we employ to authenticate the validity of the order for which you are requesting an advance payment. The most reliable delivery confirmation comes directly from your customer. Alternatively, you can submit other types of delivery confirmation documents, as listed on this page',
      heading: {
        description:
          "As we await your customer's confirmation, please feel free to upload any supporting documents or notes that can aid our team in reviewing the order. For more information, you can consult our Frequently Asked Questions.",
        linkTitle: 'Frequently Asked Questions',
        href: '#faq'
      },
      table: {
        title: 'Order summary',
        customerName: 'Customer',
        invoiceReferenceNumber: 'Invoice reference number',
        amount: 'Order value'
      },
      placeholders: {
        additionalNote: 'Please provide additional information about this invoice and/or other documents'
      },
      files: {
        invoice: {
          title: 'Invoice file',
          tag: 'Mandatory'
        },
        other: {
          title: 'Other documents',
          tag: 'Optional',
          tooltip:
            'You can upload additional documents such as time sheets, customers’ emails, or contracts to assist in reviewing the legitimacy of the order'
        },
        additionalNote: {
          title: 'Additional note',
          tag: 'Optional'
        }
      },
      submitButton: {
        title: 'Submit',
        loadingTitle: 'Uploading files...'
      },
      errors: {
        noInvoiceUploaded: 'Please provide an invoice file',
        missingOrderId: 'Provided order could not be found, please contact support'
      },
      faq: {
        title: 'FAQs',
        items: [
          {
            title: 'How does Kriya go about verifying the order with my customer?',
            description:
              "We'll send an email over to the customer using the email you've provided. Your customer can simply reply to the email to confirm – no clicking on links or sharing any data required. It's as easy as that!"
          },
          {
            title: 'If I upload documents for order review, will Kriya contact my customer?',
            description:
              'Yes.\n' +
              '\n' +
              "While we love getting confirmation from your customer - it's our top-notch way to prevent any tricky business - we understand that life can be a bit unpredictable. Sometimes, getting a response might take a detour. If that happens, no worries! Your uploaded evidence will swoop in to save the day and help us figure out if everything's on the up-and-up. We've got your back, every step of the way!"
          },
          {
            title: "How can I ensure my customer doesn't find out I'm using Kriya?",
            description:
              "Unfortunately, our initial verification method involves reaching out to your customer. This approach ensures the fastest processing and funding of your order. We're here to make the process as seamless as possible!"
          },
          {
            title: 'Why does Kriya require delivery confirmation?',
            description:
              "Unlike conventional banks, Kriya secures funds from institutions. This unique approach allows us to extend our support to customers who might be overlooked by traditional lenders. Safeguarding the interests of our investors is paramount. While we're dedicated to streamlining the funding process for faster results, we also uphold our responsibility to prevent fraudulent activities."
          },
          {
            title: 'What should I provide as delivery confirmation?',
            description:
              "For delivery confirmation, you can help verify the order's legitimacy by submitting any of the following documents:\n\n" +
              "\u2022 Customers' email confirmations or responses on the automated email\n" +
              '\u2022 Time sheets signed by the customer\n' +
              '\n' +
              'If you have alternative documents that you believe can serve as proof of delivery, kindly consult your account manager to ensure their acceptance.'
          },
          {
            title: 'What is the fastest way to get my order approved?',
            description:
              "The speediest way is receiving confirmation from your customers. However, if we don't get their confirmation, having these supporting documents as evidence will definitely assist our team in progressing through the process smoothly."
          },
          {
            title: 'How is the due date for my order determined?',
            description:
              'The due date of an order hinges on the successful confirmation of the order. When the order gets confirmed and shifts to "Ready to Advance" status, we can proceed to fund your order or invoice. This pivotal status change date becomes the starting point. Using this date along with your chosen payment method, we calculate the due date for your order. Let\'s say you set up an order with the Pay in 30 Days (Pay30) option on February 1st. If the order is confirmed on February 3rd, your customer\'s due date will be 30 days from February 3rd. You can easily spot the Payment Method and Kriya Due Date in the Order List for reference.'
          }
        ]
      }
    },
    createOrder: {
      title: 'Create an order',
      heading:
        'Receive an advance on your invoice by filling in the form below. Once submitted, we will review your order',
      orderDetails: {
        title: 'Order details',
        fields: {
          customer: {
            label: 'Who is your customer?',
            tooltip:
              'If you can’t find your customer in the list, please create a new customer first. Please note that the portal currently supports adding UK limited companies only. If your customer is a sole trader or an international company, please contact your account manager'
          },
          nationalId: {
            label: 'National ID'
          },
          invoiceReferenceNumber: {
            label: 'Invoice reference number'
          },
          amount: {
            label: 'Order amount',
            tooltip:
              'The value cannot exceed your customer’s available limit. If you would like to request a limit increase, please contact your account manager',
            errorMessages: {
              insufficientFunds: 'Order amount exceeds the available spending limit'
            }
          },
          currency: {
            label: 'Order currency'
          }
        },
        edit: {
          action: 'Edit',
          modal: {
            title: 'Do you want to change data?',
            content:
              'Changing customer or order amount may result in different fees. Please confirm to continue editing.',
            actions: {
              cancel: 'Cancel',
              continue: 'Continue'
            }
          }
        }
      },
      contactDetails: {
        title: 'Customer’s contact details',
        tooltip:
          'To prevent fraud Kriya may contact your customer to confirm the delivery of your product or service. To find out more, please read our FAQs on the homepage.',
        heading: 'We may contact your customer using these details to confirm the invoice',
        fields: {
          fullName: {
            label: 'Full name'
          },
          email: {
            label: 'Email address'
          },
          phoneNumber: {
            label: 'Phone number'
          }
        }
      },
      paymentDetails: {
        title: 'Payment method',
        heading:
          'Please select a payment method for your customer and submit the order. We will advance the fund to you as soon as the order is approved. Please note the payment term in the payment method should be longer than the original term.',
        table: {
          paymentMethod: {
            text: 'Payment method'
          },
          fee: {
            text: 'Fee (%)',
            tooltip: 'The one-off fee is determined by the payment method'
          },
          dueDate: {
            text: 'Estimated new due date',
            tooltip:
              'Your customer needs to pay their invoice to Kriya, no later than this date. In case of late payments, we will contact the customer directly. To find out more, please read our FAQs on the homepage.'
          }
        },
        summary: {
          title: 'Payment method summary',
          currencyConversion: 'In',
          amount: 'Order amount',
          advanceRate: 'Advance rate',
          fee: 'Kriya fee',
          advancePayment: 'Advance payment',
          stubPayment: {
            text: 'Stub payment',
            tooltip:
              'Once your customer has repaid us for the invoice, we will transfer the stub payment to you equal to the order value less the advance amount. '
          },
          currentSpendingLimit: 'Spending limit before the order',
          expectedSpendingLimit: 'Spending limit after the order'
        }
      },
      actions: {
        continue: 'Continue',
        cancel: 'Cancel order',
        submit: 'Submit',
        processingPaymentMethods: 'Processing payment methods...',
        startAgain: 'Start again',
        anotherCustomer: 'Choose another customer'
      }
    },
    createOrderConfirmation: {
      title: 'Order confirmation',
      deliveryConfirmationButton: 'Upload now',
      heading: [
        {
          description: 'This order has been created.'
        },
        {
          description:
            'A delivery confirmation email will be sent to your customer, and the speed of receiving the advance payment hinges on their prompt order confirmation via email. If no response is received, we may request evidence for our risk review from you. Upload at your convenience.'
        }
      ],
      footer: {
        description:
          'Please note that we will contact the customer directly in case of late payments. Find out more about our collection policy in our FAQ on the homepage.',
        linkTitle: 'homepage',
        href: routes.root
      },
      table: {
        title: 'Order summary',
        customerName: 'Customer',
        nationalId: 'National ID',
        invoiceReferenceNumber: 'Invoice reference number',
        amount: 'Order value',
        advancePayment: 'Advance payment amount',
        internalFee: 'Fee',
        internalFeePercentage: 'Fee percentage',
        paymentMethod: 'Kriya payment method',
        dueDate: 'Payment due date for your customer',
        contactFullName: 'Customer’s contact name',
        contactEmail: 'Customer’s contact email',
        contactPhoneNumber: 'Customer’s contact number'
      },
      actions: {
        orderList: {
          title: 'See all the orders',
          route: routes.orders
        },
        createOrder: {
          title: 'Create another order',
          route: routes.createOrder
        },
        uploadEvidence: {
          title: 'Upload now',
          route: routes.orderDeliveryConfirmation
        }
      }
    },
    createOrders: {
      title: 'Create orders in bulk',
      step: 'Step',
      actions: {
        continue: 'Continue',
        cancel: 'Cancel',
        back: 'Back',
        processing: 'Processing...',
        createOrders: 'Create orders',
        startAgain: 'Start again',
        anotherCustomer: 'Choose another customer'
      },
      customerDetailsStep: {
        title: 'Customer details',
        heading:
          "You can create up to 50 orders for the same customer. First, enter the customer's name and confirm the currency of the order.",
        customerContactInformationIntro:
          "Please provide your customer's contact information. We may reach out to them to verify the invoice. If you see contact details already displayed below, they were used for a previous order. If you need to update their information, please make changes directly in the form below.",
        fields: {
          customer: {
            label: 'Who is your customer?',
            tooltip:
              "If you can't find your customer in the list, please create a new customer first. Please note that the portal currently supports adding UK limited companies only. If your customer is a sole trader or an international company, please contact your account manager"
          },
          nationalId: {
            label: 'Company ID'
          },
          currency: {
            label: 'Order currency'
          },
          contactFullName: {
            label: 'Full name'
          },
          constactEmail: {
            label: 'Email'
          },
          contactPhoneNumber: {
            label: 'Phone number'
          }
        }
      },
      orderDetailsStep: {
        heading:
          "Please enter the invoice reference number and order amount for each order you'd like to create. You have the flexibility to copy and paste the information from your own spreadsheet. Additionally, you can manually enter, edit, or delete data in each cell as needed.",
        summaryDetails: {
          customer: 'Customer',
          availableLimit: 'Customer’s available spending limit',
          orderAmount: 'Order total amount',
          orderAmountOverLimitWarning: "The total shouldn’t exceed the customer's available spending limit",
          expectedTotalAdvance: 'Expected total advance',
          merchantInsufficientSpendingLimit: 'The total exceeds your available limit',
          conversionInformation: `You've selected $\{CURRENCY_CODE} for your orders, and we've converted your available spending limit and order value using the following exchange rate ($\{SELECTED_CURRENCY} = $\{CURRENCY_CONVERSION_RATE}).`
        },
        validationSummary: {
          exceedBuyerAvailableSpendingLimit: "The total order amount exceeds the customer's available spending limit.",
          hasDuplicateInvoiceNumbers:
            'Invoice reference numbers must be unique, they should not have been used previously.',
          hasPartiallyFilledOrders: 'Please provide both the invoice reference number and the order amount.',
          reachedMaximumNumberOfOrders:
            'Kindly be aware that the maximum number of orders permitted is 50. Orders exceeding this limit cannot be processed. To include additional invoices, please create a separate bulk order.',
          exceedMerchantAvailableSpendingLimit: 'The total advance amount exceeds your available limit'
        },
        addNewRows: `Add $\{ROW_COUNT} more rows`,
        clearAllRows: 'Clear all'
      },
      paymentMethodsStep: {
        title: 'Payment Methods',
        heading: 'Select a payment method for each order.',
        customer: 'Customer',
        table: {
          invoiceReference: {
            title: 'Invoice number'
          },
          invoice: {
            title: 'Invoice'
          },
          paymentMethod: {
            title: 'Payment method'
          },
          advancedPaymentAmount: {
            title: 'Advanced\r\n payment amount'
          },
          kriyaFee: {
            title: 'Kriya Fee (%)',
            tooltip: 'The one-off fee is determined by the payment method'
          },
          dueDate: {
            title: 'Customer payment due date',
            tooltip:
              'Your customer needs to pay their invoice to Kriya, no later than this date. In case of late payments, we will contact the customer directly. To find out more, please read our FAQs on the homepage.'
          },
          actions: {
            paymentMethod: 'Payment method',
            applyToAll: 'Apply to all'
          }
        },
        summary: {
          title: 'Payment summary',
          totalValue: 'Bulk order total value',
          inCompanyCurrency: 'in',
          totalCount: 'Total orders',
          advanceRate: 'Advance payment amount',
          fee: 'Kriya fee',
          netAdvancePayment: 'Net advance payment amount',
          stubPayment: {
            text: 'Stub payment',
            tooltip:
              'Once your customer has repaid us for the invoice, we will transfer the stub payment to you equal to the order value less the advance amount.'
          },
          currentSpendingLimit: 'Spending limit before the bulk order',
          expectedSpendingLimit: 'Spending limit after the bulk order'
        }
      }
    },
    createOrdersConfirmation: {
      title: 'Order confirmation',
      summary: `You have created $\{ORDER_COUNT} orders.`,
      description:
        'We will now proceed with order verification. Depending on the value of the order, we may contact your customer by email for the purpose of verification. If no response is received, we may request evidence for our risk review from you. Upload at your convenience.',
      actions: {
        seeAllOrders: {
          route: routes.orders,
          title: 'See all the existing orders'
        }
      },
      table: {
        title: 'Bulk order summary',
        rows: {
          company: 'Company',
          companyId: 'Company ID',
          ordersCount: 'Total orders',
          totalOrderValue: 'Total order value',
          totalAdvancePayment: 'Total advance amount',
          totalInternalFee: 'Total Kriya fee',
          customerName: 'Customer contact’s name',
          customerEmail: 'Customer contact’s email',
          customerPhoneNumber: 'Customer contact’s phone number'
        }
      },
      footer: {
        text: 'Please note that we will contact the customer directly in case of late payments. Find out more about our collection policy in our FAQ on the homepage.',
        linkTitle: 'homepage',
        href: routes.root
      }
    },
    customerInformation: {
      rejected: {
        title: 'This customer is not eligible for Kriya Payments',
        description:
          'Unfortunately, you can’t create an order for this customer. Need help? Contact your account manager'
      },
      inReview: {
        title: 'Our team is currently reviewing this customer',
        description: 'Go to View Existing Customers to track the review result',
        linkTitle: 'View Existing Customers'
      },
      kycCheckNotStarted: {
        title: "This customer's spending limit needs activation",
        linkTitle: 'Activate'
      },
      kycCheckInProgress: {
        title: "This customer's Director ID Verification result is in progress",
        description:
          'They must pass the verification to finalise onboarding. Check the current status in the Existing Customers list',
        linkTitle: 'the Existing Customers list'
      },
      insufficientFunds: {
        title: 'The customer’s spending limit summary',
        description: {
          totalSpendingLimit: 'Total spending limit:',
          availableSpendingLimit: 'Available spending limit:',
          needHelp: 'Need help? Contact your account manager'
        }
      },
      valid: {
        title: 'The customer’s spending limit summary',
        description: {
          totalSpendingLimit: 'Total spending limit:',
          availableSpendingLimit: 'Available spending limit:'
        }
      }
    },
    customerOnboarding: {
      title: 'Onboard a new customer',
      loading: {
        title: 'Checking your customer’s eligibility and spending limit...',
        closeText: 'Please don’t close the window.'
      },
      form: {
        title: 'Find the company',
        fields: {
          country: 'Country',
          state: 'Region',
          company: 'Company'
        },
        buttons: {
          submit: 'Submit'
        }
      },
      failure: {
        title: 'Something went wrong',
        description: 'Apologies, we have encountered an error processing your request. Please try again later.',
        actions: {
          homepage: {
            title: 'Back to home',
            route: routes.root
          }
        }
      },
      summary: {
        title: {
          approved: 'Customer onboarded!',
          rejected: 'We’re sorry!',
          other: 'Manual review required'
        },
        description: {
          approved: 'Thank you for adding a new customer. You can now start creating orders for this customer.',
          rejected: 'This company has not passed our assessment.',
          other:
            'Thank you for adding a new customer. This company requires a manual review. We will email you the result once our team completed the risk assessment.'
        },
        table: {
          title: 'Customer onboarding summary',
          rows: {
            companyName: 'Company name',
            countryOfIncorporation: 'Country of incorporation',
            state: 'State/Province/Regions',
            nationalId: 'Company ID',
            companyAddress: 'Company Address',
            decision: 'Risk decision',
            spendingLimit: 'Total spending limit',
            usedLimit: {
              title: 'Used limit',
              tooltip:
                'This customer might have been introduced into our system by a different merchant, where they may have already used their spending limit for their purchase.'
            },
            availableLimit: 'Available limit',
            rejectionReasons: 'Reasons for rejection',
            directorIdVerification: 'Director ID verification'
          }
        },
        riskDecision: {
          approved: 'Approved',
          rejected: 'Rejected',
          other: 'Manual review required'
        },
        actions: {
          customers: {
            title: 'Go to list of customers',
            route: routes.buyers
          },
          onboard: {
            title: 'Onboard another customer',
            route: routes.customerOnboarding
          }
        }
      },
      directorIdVerificationInProgress: {
        title: 'Director ID verification in progress',
        description: "You've initiated the director ID verification process for ${COMPANY_NAME}.",
        body: {
          firstLineParagraph:
            'Currently, we are awaiting the results from ${DIRECTOR_NAME}. Possible reasons for the delay include:',
          reasons: [
            'The designated director has not yet started the verification process.',
            'They have initiated the process but have not completed it.',
            'The process is complete, but the data requires manual review.'
          ],
          lastLineParagraph:
            'Thank you for your patience as we ensure a thorough and accurate verification process for ${COMPANY_NAME}.'
        },
        actions: {
          customers: {
            title: 'Go to list of customers',
            route: routes.buyers
          },
          onboard: {
            title: 'Onboard another customer',
            route: routes.customerOnboarding
          }
        }
      }
    },
    buyerDetails: {
      title: 'Activate spending limit',
      kycActivation: {
        existingCustomerListLink: 'the existing customer list',
        verificationLinkHasBeenEmailed:
          "Thank you! The verification link has been emailed to them. Once they finish director ID verification, we'll update you via email. Track the progress in the existing customer list in the meantime.",
        unableToInitiateVerification: 'Sorry. We are unable to initiate the director ID verification. Please try again.'
      },
      form: {
        title: 'Great! [Buyer] has been approved for a spending limit of [amount].',
        description:
          "Please enter your customer's details below. We will send them an instruction email where they can follow a link to complete the director ID verification.",
        fields: {
          name: {
            label: "Customer's contact name",
            validation: {
              required: 'Required field',
              maxLength: 'Field value must not exceed 128 characters'
            }
          },
          email: {
            label: "Customer's contact email",
            validation: {
              required: 'Required field',
              maxLength: 'Field value must not exceed 128 characters',
              invalid: 'Field value must be a valid email address'
            }
          },
          phoneNumber: {
            label: "Customer's phone number",
            validation: {
              required: 'Required field',
              maxLength: 'Field value must not exceed 128 characters',
              invalid: 'Field value must be a valid phone number'
            }
          }
        },
        offlineActivation: {
          description:
            'Should the director be unable to complete the ID verification promptly, please email us at customersupport@kriya.co. Our team will assist you in completing alternative verification checks as soon as possible.',
          linkTitle: 'customersupport@kriya.co',
          href: 'mailto:customersupport@kriya.co'
        },
        button: 'Send verification link'
      },
      spendingLimitSummary: {
        title: 'Spending limit summary',
        items: {
          result: 'Result',
          totalSpendingLimit: 'Total spending limit',
          usedLimit: 'Used limit',
          usedLimitTooltip:
            'This customer might have been introduced into our system by a different merchant, where they may have already used their spending limit for their purchase.',
          availableLimit: 'Available limit'
        },
        description:
          'To start placing orders, [Buyer] will need to activate the limit by completing director ID verification.'
      },
      companyInformation: {
        title: 'Company information',
        items: {
          companyName: 'Company name',
          countryOfIncorporation: 'Country of incorporation',
          companyId: 'Company ID',
          companyAddress: 'Company Address'
        }
      }
    },
    bulkCustomersOnboarding: {
      title: 'Bulk onboard new customer',
      successMessage:
        'The file has been submitted successfully. We will process it and send an email once the analysis is completed.',
      buttons: {
        onboard: {
          title: 'Onboard more customers'
        },
        homePage: {
          title: 'Back to homepage',
          route: routes.root
        },
        submitButton: {
          title: 'Submit',
          processingTitle: 'Analysing...',
          tryAgainTitle: 'Try again'
        }
      },
      bulkUpload: {
        title: "Before uploading any files, it's essential to keep a few key points in mind:",
        pointers: {
          exampleSheet: {
            description:
              'For bulk company uploads, ensure you submit a CSV format list of buyers, and you can refer to the example sheet provided for guidance.',
            linkTitle: 'the example sheet',
            href: 'https://cdn.kriya.co/files/BulkBuyerUpload-Example.csv'
          },
          unique: 'Please be aware of the limit of 500 unique companies per week for uploads.',
          supportedCountries:
            "It's important to note that our system currently exclusively supports UK limited companies."
        },
        validationError: {
          emptyOrInvalidFile: 'Please upload a valid CSV file',
          fileTooLarge: 'File cannot exceed 2MB'
        }
      },
      limitReached: {
        title: 'You have exceeded your weekly quota',
        description:
          'We appreciate your interest, however, you have reached your weekly limit for onboarding buyers. Please try again on Monday. If you have an urgent bulk upload request, please contact your account manager directly.',
        subDescription: " You can still check the customer limit with 'single company entry'"
      }
    },
    customerPaymentsList: {
      title: 'Customer payments',
      table: {
        columns: {
          view: 'View',
          receivedDate: 'Received date',
          paymentStatus: 'Payment status',
          processedDate: 'Processed date',
          paymentAmount: 'Payment amount',
          companyName: 'Company name',
          paymentReference: 'Payment reference',
          repaidInvoice: 'Repaid invoice',
          unrelatedFunds: 'Unrelated funds'
        }
      },
      filter: {
        search: {
          label: 'Search',
          placeholder: 'Enter invoice number, payment reference'
        },
        company: {
          label: 'Company name'
        },
        paymentStatus: {
          label: 'Payment status',
          all: 'All'
        },
        moreFilters: {
          text: 'more filters'
        },
        currency: {
          label: 'Currency'
        },
        receivedDate: {
          label: 'Received date'
        },
        processedDate: {
          label: 'Processed date'
        },
        withUnrelatedFundsOnly: {
          label: 'Unrelated fund amount >0'
        }
      },
      export: {
        button: 'Export',
        dialog: {
          heading: `We’ll email you the report`,
          body: `Thank you for requesting the report. We're exporting the data of the last 12 months and it will be sent to your inbox.`,
          action: 'OK, I understand'
        }
      }
    },
    customerPaymentDetails: {
      title: 'Payment details',
      breadcrumb: {
        title: 'List of customer payments',
        route: routes.payments
      },
      summary: {
        table: {
          title: 'Summary',
          rows: {
            receivedDate: 'Received date',
            paymentStatus: 'Payment status',
            processedDate: 'Processed date',
            paymentAmount: 'Payment amount',
            companyName: 'Company name',
            paymentReference: 'Payment reference'
          }
        }
      },
      allocations: {
        title: 'Payments allocation',
        notProcessedText: `Once your payment is reconciled, we'll provide a breakdown of the allocation details.`,
        table: {
          invoiceNumber: {
            text: 'Invoice number'
          },
          prepayment: {
            text: 'Prepayment'
          },
          advance: {
            text: 'Advance'
          },
          stub: {
            text: 'Stub'
          },
          unrelated: {
            text: 'Unrelated'
          }
        }
      },
      note: {
        title: 'Note from Kriya',
        updateDate: 'Last updated: '
      },
      remittanceAdvice: {
        title: 'Remittance advice',
        commonUploadError: 'File upload failed',
        uploader: {
          acceptedFiles: 'pdf, png, jpeg, msg, tiff'
        }
      }
    },
    merchantInformation: {
      merchantSpendingLimit: {
        title: 'Your limit summary',
        assignedLimit: 'Assigned limit',
        availableLimit: 'Available limit',
        description: 'The limit is insufficient to cover the advance amount for this order.',
        message:
          'Unfortunately, you cannot create a new order due to an insufficient limit. Need help? Please contact your account manager.'
      }
    }
  },
  components: {
    genericPaymentSummary: {
      orderExchangeRate: "We've used the following exchange rate",
      defaultExchangeRate: "to calculate the order's impact on the spending limit."
    },
    countryPicker: {
      placeholder: 'Select country'
    },
    regionPicker: {
      placeholder: 'Select state/province'
    },
    companyPicker: {
      placeholder: 'Type a name or a registration number'
    },
    searchComponent: {
      placeholder: 'Type a name or a registration number'
    },
    marketplacePicker: {
      placeholder: 'Search'
    }
  },
  table: {
    emptyResultsText: 'There is no matching result. Please refine your search.',
    // eslint-disable-next-line no-template-curly-in-string
    paginationSummary: 'Displaying: ${from}-${to} of ${total}'
  },
  filesUpload: {
    mandatory: 'Mandatory',
    optional: 'Optional',
    dropZoneTitle: 'Drag and drop a file here, or',
    dropZoneTitleClickToUpload: 'click to upload',
    dropZoneInvalidFileType: 'File type must be ',
    dropZoneSupportedFileTypes: 'Supported file formats ',
    fileTooBig: 'The uploaded file is too big',
    fileTooSmall: 'The uploaded file is too small'
  }
}

export default content
